import { useEffect, useState } from 'react';
import './App.css';
import contract from './contracts/Sweep.json';
import { ethers } from 'ethers';

const contractAddress = "0x408e982FF7227B62c93A58Fe03F92D3a2Ea24225";
const abi = contract.abi;

function App() {

  const [currentAccount, setCurrentAccount] = useState(null);

  const checkWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Make sure you have Metamask installed!");
      return;
    } else {
      console.log("Wallet exists! We're ready to go!")
    }

    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account: ", account);
      setCurrentAccount(account);
    } else {
      console.log("No authorized account found");
    }
  }

  const connectWalletHandler = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      alert("Please install Metamask!");
    }

    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      console.log("Found an account! Address: ", accounts[0]);
      setCurrentAccount(accounts[0]);

    } catch (err) {
      console.log(err)
    }
  }

  const mintNftHandler = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);

        console.log("Initialize payment");
        let nftTxn = await nftContract.BuyforRewardsUsingTreasury({ value: ethers.utils.parseEther("0.01") });

        console.log("Mining... please wait");
        await nftTxn.wait();

        console.log(`Mined, see transaction: https://bscscan.com/tx/${nftTxn.hash}`);

      } else {
        console.log("Ethereum object does not exist");
      }

    } catch (err) {
      console.log(err);
    }
  }

  const mintNftHandler2 = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);

        console.log("Initialize payment");
        let nftTxn = await nftContract.BuyforRewardsUsingTreasury({ value: ethers.utils.parseEther("0.1") });

        console.log("Mining... please wait");
        await nftTxn.wait();

        console.log(`Mined, see transaction: https://bscscan.com/tx/${nftTxn.hash}`);

      } else {
        console.log("Ethereum object does not exist");
      }

    } catch (err) {
      console.log(err);
    }
  }

  const connectWalletButton = () => {
    return (
      <button onClick={connectWalletHandler} className='cta-button connect-wallet-button'>
        Connect Wallet
      </button>
    )
  }

  const mintNftButton = () => {
    return (
      <button onClick={mintNftHandler} className='cta-button mint-nft-button'>
        MCC Buy Back (0.01 ETH/BNB/FTM)
      </button>
    )
  }

  const mintNftButton2 = () => {
    return (
      <button onClick={mintNftHandler2} className='cta-button mint-nft-button'>
        MCC Buy Back (0.1 ETH/BNB/FTM)
      </button>
    )
  }

  useEffect(() => {
    checkWalletIsConnected();
  }, [])

  return (
    <div className='main-app'>
      <h1>MCC Buy Back</h1>
      <img src="/apple-icon.png"></img>
      <div>
        {currentAccount ? mintNftButton() : connectWalletButton()}
      </div>
      <br></br>
      <div>
        {currentAccount ? mintNftButton2() : ""}
      </div>
      <br></br>
      <center>
        10% Fee to Prevent Abuse or Front Running.
        <br></br>
        5% of the fee is paid out in MCC to the caller.
        <br></br>
      <iframe width="600" height="500" frameborder="0" scrolling="no" src="https://buyback.mchain.capital/balance"></iframe>
      </center>
    </div>
  )
}

export default App;
